import { render, staticRenderFns } from "./Post.vue?vue&type=template&id=4dde7ca3&scoped=true&"
import script from "./Post.vue?vue&type=script&lang=js&"
export * from "./Post.vue?vue&type=script&lang=js&"
import style0 from "./Post.vue?vue&type=style&index=0&id=4dde7ca3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dde7ca3",
  null
  
)

component.options.__file = "Post.vue"
export default component.exports