<template>
  <div id="novaMensagem" class="message new">
    <h2 class="left">Nova Mensagem</h2>
    <div class="box">
      <div class="content-box">
        <form action="" id="novaMensagemForm">
          <div class="row">
            <textarea name="content" placeholder="Digite aqui sua mensagem" v-model="content"></textarea>
          </div>
          <div class="row">
            <div class="eight">
              <input type="submit" id="enviarMensagem" value="Enviar Mensagem" class="submit" v-on:click="createPost">
            </div>
            <div class="eight right">
              <input-file ref="inputFile"></input-file>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>  <!-- end of mensagem  -->
</template>

<script>
  import { getAuthenticatedUser, createPost } from '@/services/api'
  import InputFile from '../shared/InputFile.vue'

  export default {
    name: 'NewMessage',
    components: {
      InputFile
    },
    props: {
      onRefresh: Function
    },
    data () {
      return {
        content: ''
      }
    },
    methods: {
      async createPost (event) {
        event.preventDefault()

        const user = getAuthenticatedUser()
        const data = new FormData();
        const files = this.$refs.inputFile.$attachments.files || $('#attachments')[0].files

        data.append('content', this.content)
        data.append('agent_id', user.sub)

        for (let index = 0; index < files.length; index++) {
          data.append('files[]', files[index])
        }

        const post = await createPost(this.$route.params.id, data)

        this.clearMessage()
        this.onRefresh()
      },
      clearMessage () {
        this.content = ''
        $(this.$refs.inputFile.$attachments)
          .val(null)
          .get(0)
          .dispatchEvent(new Event('change'))
      }
    }
  }
</script>

<style scoped>
</style>

