export function execute (context, callable, options = null) {
  if (!callable) {
    return null
  }

  if (typeof callable !== 'function') {
    return callable
  }

  return callable.bind(context)(options)
}

export function removeEmpty (obj) {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key])
    else if (obj[key] === undefined || obj[key] === null || obj[key] === '') delete obj[key]
  })
}
