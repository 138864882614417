<template>
  <div class="success">
    <div class="alert green center">
        Ticket finalizado <strong>{{code}}</strong>
    </div>
    <div class="content">
      <p>Seu ticket foi finalizado com sucesso. Foi enviado para você um e-mail com as informações de seu ticket. </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClosedBox',
  props: {
    code: String
  }
}
</script>

