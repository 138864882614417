<template>
  <div class="box tabela">
    <table class="table" :data-uuid="uuid">
      <tr>
        <th v-for="column in columns">{{column.head}}</th>
      </tr>
    </table>
  </div>
</template>

<script>
import $ from 'jquery'
import uuid from 'uuid/v4'

function renderDatatable ($component, data, columns, searching = false, ordering = false) {
  return $component.DataTable({
    searching,
    ordering,
    data,
    columns,
    rowCallback: function (row, data) {
      $(row).attr('data-id', data.id)
    }
  })
}

export default {
  name: 'DataTables',
  props: {
    data: Array,
    columns: Array
  },
  methods: {
    async mountTable (data, columns) {
      this._table && this._table.destroy()
      this._table = renderDatatable(this._$component, data, columns)
    }
  },
  created () {
    this.uuid = uuid()
  },
  mounted () {
    this._$component = $(`table[data-uuid=${this.uuid}]`)

    this.mountTable(
      this.data,
      this.columns
    )
  },
  watch: {
    data (data) {
      this.mountTable(data, this.columns)
    },
    columns (columns) {
      this.mountTable(this.data, columns)
    }
  }
}
</script>

<style scoped>

</style>
