<template>
  <div class="login">
    <h2>Login</h2>
    <div class="box">
      <div class="content-box">
        <form action="">
          <div class="linha">
            <label>Email</label>
            <input type="text" v-model="login">
          </div>
          <div class="linha">
            <label>Senha</label>
            <input type="password" v-model="password">
          </div>
          <button type="button" class="submit" v-on:click="authenticate">Entrar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { authenticate } from '../services/api'
import Input from '../components/shared/Input'
import Button from '../components/shared/Button'

export default {
  name: 'Login',
  data () {
    return {
      login: '',
      password: '',
      code: ''
    }
  },
  components: {
    Input,
    Button
  },
  methods: {
    async authenticate (e, login, password) {
      login = login || this.login
      password = password || this.password

      const response = await authenticate(login, password)

      if (response) {
        this.$router.push('/tickets')
      } else {
        alert('Usuário e/ou senha inválidos.')
      }
    }
  }
}
</script>
