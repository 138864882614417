<template>
  <div class="input-file">
    <label class="">
      <input type="file" id="attachments" name="attachments[]" style="display: none" multiple="multiple" @change="changeInput">
      <span class="if-filename"><i class="fa fa-paperclip"></i> Somente PDF, PNG ou JPG.</span>
    </label>
    <button type="button" class="if-btn-remove" @click="clearFiles">
      <i class="fa fa-times"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'InputFile',
  mounted () {
    this.$attachments = $('[name="attachments[]"]')
  },
  methods: {
    changeInput (event) {
      const $this = $(this)
      const files = event.target.files
      const $fname = $('.if-filename')
      const $icon = $fname.find('i')
      const defaultIcon = '<i class="fa fa-paperclip"></i>'
      const text = $fname.text();
      const defaultText = 'Somente PDF, PNG ou JPG.'

      $fname.html(`${defaultIcon}`);

      if (!files.length) {
        $fname.append(` ${defaultText}`)
      } else if (files.length == 1) {
        $fname.append(` ${files[0].name}`)
      } else {
        $fname.append(` ${files.length} arquivos`)
      }
    },
    clearFiles () {
      this.$attachments.val(null)
      this.$attachments[0].dispatchEvent(new Event('change'))
    }
  }
}
</script>

<style>

</style>

