<template>
  <data-tables :data="tickets" :columns="columns"/>
</template>

<script>
import moment from 'moment'
import DataTables from '../DataTables'

const STATUS = {
  'opened': 'Em aberto',
  'pending': 'Em andamento',
  'answered': 'Em andamento',
  'closed': 'Finalizado'
}

function renderActions (data, type, row, meta) {
  return (
    `<div class="action_buttons">
      <a href="javascript:void(0)" class="">
        <i class="far fa-comments-alt" title="Acompanhar"></i>
      </a>
    </div>`
  )
}

function renderStatus (data, type, row, meta) {
  return STATUS[data]
}

function renderRespondido (data, type, row, meta) {
  return data === 'answered' ? '<div align="center"><i class="fas fa-check"></i></div>' : ''
}

function renderLastUpdate (data, type, row, meta) {
  return moment(data, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')
}

const columns = [
  {
    head: 'Cliente',
    data: 'organization_name'
  },
  {
    head: 'Assunto',
    data: 'subject'
  },
  {
    head: 'Departamento',
    data: 'department_name'
  },
  {
    head: 'Úlima atualização',
    data: 'updated_at',
    render: renderLastUpdate
  },
  {
    head: 'Status',
    data: 'status',
    render: renderStatus
  },
  {
    head: 'Respondido',
    data: 'status',
    render: renderRespondido
  },
  {
    head: 'Ações',
    data: 'id',
    render: renderActions
  }
]

export default {
  name: 'TicketsTable',
  components: {
    DataTables
  },
  props: {
    tickets: Array
  },
  data () {
    return { columns }
  },
  mounted () {
    const self = this
    const $el = $(this.$el)
    const $table = $el.find('table').DataTable()

    $table.on('click', 'tr td:last-child', function (e) {
      const id = $(this).closest('tr').attr('data-id')

      self.$router.push({ name: 'ticket', params: { id } })
    })
  }
}
</script>
