const listeners = []
let hasUpdate = false

function notify () {
  listeners.forEach(listener => listener())
}

export function updateAvailable () {
  hasUpdate = true

  notify()
}

export function addUpdateListener (listener) {
  listeners.push(listener)

  if (hasUpdate) {
    listener()
  }
}
