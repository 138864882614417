<template>
  <div class="tickets_page">
    <ticket-filters :on-search="fetchTickets"/>
    <tickets-table :tickets="tickets"/>
  </div>
</template>

<script>
import TicketFilters from '../components/tickets/TicketFilters'
import TicketsTable from '../components/tickets/TicketsTable'
import { getTickets } from '../services/api'

export default {
  name: 'Tickets',
  components: {
    TicketFilters,
    TicketsTable
  },
  data () {
    return {
      tickets: [],
      filters: {
        organization_name: 1
      }
    }
  },
  mounted () {
    this.fetchTickets(this.filters)
  },
  methods: {
    async fetchTickets (filters = {}) {
      this.tickets = await getTickets(filters)
    }
  }
}
</script>
