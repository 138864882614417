import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login.vue'
import Tickets from './views/Tickets.vue'
import TicketDetail from './views/TicketDetail.vue'
import { getAuthenticatedUser } from './services/api';

Vue.use(Router)

const AUTH = true;

const router = new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/tickets',
      name: 'tickets',
      component: Tickets,
      meta: {
        requiredAuth: AUTH
      }
    },
    {
      path: '/ticket/:id',
      name: 'ticket',
      component: TicketDetail,
      meta: {
        requiredAuth: AUTH
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const user = getAuthenticatedUser();

  if (!user && to.matched.some(record => record.meta.requiredAuth)) {
    next({ name: 'login', query: { redirect: to.fullPath }})
  } else {
    if (user && to.path === '/') {
      return;
    }
  }

  next();
});

export default router
