<template>
  <div class="filters">
    <form action="" v-on:submit="searchHandler">
      <div class="row">
        <div class="five">
          <input type="text" placeholder="Assunto" v-model="subject">
        </div>
        <div class="six">
          <select name="" id="" v-model="organization" v-on:change="searchHandler">
            <option v-for="organization in this.organizations" :key="organization.id" :value="organization.id">
              {{ organization.name }}
            </option>
          </select>
        </div>
        <div class="three">
          <select name="" id="" v-model="status" v-on:change="searchHandler">
            <option value="">Todos</option>
            <option value="opened">Em aberto</option>
            <option value="in-progress">Em andamento</option>
            <option value="closed">Finalizado</option>
          </select>
        </div>
        <div class="inline">
          <button class="botao azul" v-on:click="searchHandler">Buscar</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { execute } from '../../support/utils'
import { getOrganizations } from '../../services/api'

export default {
  name: 'TicketFilters',
  data () {
    return {
      subject: '',
      status: '',
      organization: null,
      organizations: []
    }
  },
  props: {
    onSearch: Function
  },
  async mounted () {
    const organizations = await getOrganizations()

    organizations.unshift({ id: null, name: 'Todos' })

    this.organizations = organizations
    this.refreshTickets(30)
  },
  beforeDestroy () {
    clearInterval(this.intervalId)
  },
  methods: {
    doSearch () {
      execute(this, this.onSearch, {
        subject: this.subject,
        status: this.status,
        organization_id: this.organization,
        organization_name: 1
      })
    },
    searchHandler (event) {
      event.preventDefault()

      this.doSearch()

      return false
    },
    refreshTickets (seconds) {
      this.intervalId = setInterval(() => this.doSearch(), seconds * 1000)
    }
  }
}
</script>
