import { render, staticRenderFns } from "./UpdatedModal.vue?vue&type=template&id=765766fd&scoped=true&"
import script from "./UpdatedModal.vue?vue&type=script&lang=js&"
export * from "./UpdatedModal.vue?vue&type=script&lang=js&"
import style0 from "./UpdatedModal.vue?vue&type=style&index=0&id=765766fd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "765766fd",
  null
  
)

component.options.__file = "UpdatedModal.vue"
export default component.exports