import axios from 'axios'
import jwt from 'jsonwebtoken'
import { execute, removeEmpty } from '../support/utils'

const SESSION_TOKEN = 'Elodesk-Token'

const onAuthStatusChangeListeners = []

const session = {
  token: sessionStorage.getItem(SESSION_TOKEN)
}

async function notify () {
  onAuthStatusChangeListeners.forEach(callback => execute(this, callback, session.user))
}

function http () {
  return axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: { 'Authorization': `Bearer ${session.token}` }
    // validateStatus: () => true
  })
}

export async function authenticate (login, password, code) {
  try {
    const domain = window.location.protocol + '//' + window.location.hostname

    const { status, data } = await http().post('/auth/signin', {
      login, password, code, domain
    })

    if (status !== 200) {
      return null
    }

    const { token } = data
    const decoded = jwt.decode(token)

    Object.assign(session, {
      authenticated: true,
      user: decoded,
      token
    })

    sessionStorage.setItem(SESSION_TOKEN, token)

    notify()

    return decoded
  } catch (error) {
    console.log(error)
  }
}

export async function logout () {
  sessionStorage.removeItem(SESSION_TOKEN)
  session.token = ''
  session.user = null

  notify()
}

export async function loadUser () {
  try {
    const { data } = await http().get('/auth/me')

    Object.assign(session, {
      authenticate: !!data,
      user: data
    })

    return data
  } catch (error) {
    console.log(error)
  }
}

export function getAuthenticatedUser () {
  return session.user || null
}

export function onAuthStatusChange (callback) {
  onAuthStatusChangeListeners.push(callback)
}

function query (params) {
  const queryString = Object.keys(params).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
  }).join('&')

  return queryString
}

export async function getClients (filters = {}) {
  const { data } = await http().get(`/clients`, { params: filters })

  return data.tickets
}

export async function getTickets (filters = {}) {
  removeEmpty(filters)

  const { data } = await http().get(`/tickets`, { params: filters })

  return data.tickets
}

export async function getTicket (id, filters = {}) {
  const { data } = await http().get(`/tickets/${id}`, { params: filters })

  return data.ticket
}

export async function getPosts (id, filters = {}) {
  const { data } = await http().get(`/tickets/${id}/posts`, { params: filters })

  return data.posts
}

export async function createPost (ticketId, values = {}) {
  const { data } = await http().post(`/tickets/${ticketId}/posts`, values)

  return data.post
}

export async function getDepartments (values = {}) {
  const { data } = await http().get(`/departments`, values)

  return data.departments
}

export async function getOrganizations (values = {}) {
  const { data } = await http().get(`/organizations`, values)

  return data.organizations
}

export async function downloadAttachment (attachmentId, filters = {}) {
  const params = { download: 1 }
  const options = { responseType: 'blob' }
  const r = await http()
    .get(`/attachments/${attachmentId}`, {params}, options)
    .then(prepareDownload)
}

const prepareDownload = (res) => {
  downloadBase64(res)
}

const makeDownloadLink = (blob, filename) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.setAttribute('href', url);
  link.setAttribute('download', filename);

  document.body.appendChild(link);
  link.click();
  link.remove();
  window.URL.revokeObjectURL(url);
}

const downloadBlob = (response) => {
  const blob = new Blob([response.data], { type: response.data.type });
  const filename = getFilename(response);

  makeDownloadLink(blob, filename);
}

const downloadBase64 = (response) => {
  const blob = base64toBlob(response.data);
  const filename = getFilename(response);

  makeDownloadLink(blob, filename);
}

const getFilename = (response) => {
  const contentDisposition = response.headers['content-disposition'] || '';

  let filename = 'unknown';
  if (contentDisposition) {
    const filenameMatch = contentDisposition.match(/filename="(.+)"/);
    if (filenameMatch.length === 2)
      filename = filenameMatch[1];
  }

  return filename;
}

const base64toBlob = (base64Data, contentType) => {
  contentType = contentType || '';
  var sliceSize = 1024;
  var byteCharacters = atob(base64Data);
  //var byteCharacters = decodeURIComponent(escape(window.atob(base64Data)))
  var bytesLength = byteCharacters.length;
  var slicesCount = Math.ceil(bytesLength / sliceSize);
  var byteArrays = new Array(slicesCount);

  for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0 ; offset < end; ++i, ++offset) {
          bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}
