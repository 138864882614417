<template>
  <div class="">
    <closed-box v-if="this.ticket.status == 'closed'" :code="this.ticket.id"/>
    <new-message :on-refresh="fetchPosts"/>
    <div v-for="post in this.posts" :key="post.id">
      <post :class="post.agent_id ? 'assignee' : 'client'" :data="post" :ticket="ticket"/>
    </div>
  </div>
</template>

<script>
import ClosedBox from '@/components/tickets/ClosedBox'
import NewMessage from '../components/tickets/NewMessage'
import Post from '../components/tickets/Post'
import { getTicket, getPosts } from '@/services/api'

export default {
  name: 'TicketDetail',
  components: {
    ClosedBox,
    NewMessage,
    Post
  },
  data () {
    return {
      ticket: {},
      posts: [],
      filters: {
        name: 1,
        organization_name: 1
      }
    }
  },
  mounted () {
    this.fetchTicket()
    this.fetchPosts()
  },
  methods: {
    async fetchTicket (filters = {}) {
      const ticket = await getTicket(this.$route.params.id, this.filters)

      this.ticket = ticket
    },
    async fetchPosts (filters = {}) {
      this.posts = await getPosts(this.$route.params.id)
    }
  }
}
</script>
