<template>
  <div id="app">
    <updated-modal v-if="updated" v-model="updated" @close="refreshPage"/>
    <app-header :user="user"/>
    <main>
      <router-view/>
    </main>
    <app-footer />
  </div>

</template>

<script>
import { addUpdateListener } from './services/updateManager'
import { getAuthenticatedUser, onAuthStatusChange } from './services/api'
import AppHeader from './components/shared/AppHeader'
import AppFooter from './components/shared/AppFooter'
import UpdatedModal from './UpdatedModal'

export default {
  name: 'App',
  components: { AppHeader, AppFooter, UpdatedModal },
  data () {
    return {
      show: false,
      authenticated: false,
      user: {},
      updated: false
    }
  },
  created () {
    const user = getAuthenticatedUser()

    this.user = user

    onAuthStatusChange(this.updateSession)

    if (this.user && this.$route.path === '/') {
      return this.$router.push('/tickets')
    }
  },
  mounted () {
    addUpdateListener(() => {
      this.updated = true
    })
  },
  methods: {
    updateSession (user) {
      this.user = user
    },
    refreshPage () {
      window.location.reload()
    }
  }
}
</script>

<style lang="scss">

  @import './assets/scss/main.scss';

  body {
    margin: 0;
    background: #f5f7fa;
  }

</style>
