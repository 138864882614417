import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import './3rd/jquery'
import './3rd/libs'
import { loadUser } from './services/api';

Vue.config.productionTip = false

loadUser().then(() => {
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
})
