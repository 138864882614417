<template>
  <div class="row">
    <input type="button" :value="label">
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    label: String
  }
}
</script>
