<template>
  <div class="row">
    <label>{{label}}</label>
    <input :type="type" v-on:change="changeHandler"/>
  </div>
</template>

<script>
export default {
  name: 'Input',
  props: {
    label: String,
    type: String,
    onChange: Function
  },
  methods: {
    changeHandler (e) {
      console.log(this)
    }
  }
}
</script>
