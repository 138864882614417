<template>
  <header>
    <div class="layout">
      <img src="img/logo.png" class="logo"/>
      <nav v-if="user">
        <ul>
          <li><router-link to="/">Dashboard</router-link></li>
          <li><router-link to="/tickets">Tickets</router-link></li>
          <li><router-link to="/">Perfil</router-link></li>
          <li><a href="javascript:void(0)" v-on:click="logout($event)">Logout</a></li>
        </ul>
      </nav>
      <a href="#" class="responsivo modal-trigger" data-id="menu">
        Abrir Menu
      </a>
    </div>
  </header>
</template>

<script>
  import { logout } from '../../services/api';

  export default {
    name: 'AppHeader',
    props: {
      authenticated: Boolean,
      user: Object,
    },
    methods: {
      logout (event) {
        event.preventDefault()
        logout()
        this.$router.push('/')
      }
    }
  }
</script>

