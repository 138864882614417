<template>
  <div class="message_main" :class="role">
    <div class="box">
      <div class="base">
        <div class="ckeditor">
          <p>{{content}}</p>
        </div>
      </div>
      <div class="top">
        <div class="left">
          <p class="role">{{type}}</p>
          <p class="name">{{name}}</p>
          <p class="date">{{date}}</p>
        </div>
        <div class="">
          <span class="attachment" v-for="attachment in this.data.attachments" :key="attachment.id" @click="downloadAttachment(attachment)">
            {{ attachment.identifier }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadAttachment as download } from '../../services/api'

export default {
  name: 'Post',
  props: {
    data: Object,
    ticket: Object
  },
  data () {
    return {
      type: '',
      name: '',
      date: '',
      content: '',
      role: ''
    }
  },
  mounted () {
    const data = this.data

    this.type = data.agent_id ? 'Atendente: ' : `${this.ticket.organization_name}: `
    this.name = data.agent_id ? data.agent_name : this.ticket.requester_name
    this.date = data.created_at
    this.content = data.body
  },
  methods: {
    downloadAttachment (attachment) {
      download(attachment.id)
    }
  }
}
</script>

<style scoped>
</style>
